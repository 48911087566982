body {
  overflow-x: hidden;
  padding: 0%;
  margin: 0;
}
#root {
  overflow-x: hidden;
}
.container_with_shadow {
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  /* border-radius: 10px; */
  padding: 10px;
  margin: 10px;
  background-color: #191c24;
  border-radius: 5px;
}

.text_grey {
  color: white;
}
.power_values {
  color: #9e9e9e;
}
.power_units {
  color: #00d25b;
  font-size: 15px;
}
