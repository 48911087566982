.gauge_consumotionpage {
  position: relative;
  height: 200px;
}
.gauge_consumotionpage h4 {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -40%);
}
/* .left_status_container {
} */
.gauge_chart_text {
  position: absolute;
  bottom: 20px;
}
.gauge_container {
  position: relative;
  height: 245px;
}
.texttransform {
  position: relative;
  /* color:red;
  -webkit-transform: rotate(90deg);
-moz-transform: rotate(90deg);
-o-transform: rotate(90deg);
-ms-transform: rotate(90deg); */
  text-transform: rotate(300deg);
  writing-mode: vertical-lr;
}
.consumptionunit {
  position: relative;
  top: -10%;
  color: #0d6ee6;
  text-align: center;
  font-size: 2px;
}
.energy_up {
  background: #183022;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  position: relative;
}
.energy_up svg {
  position: absolute;
  left: 8px;
  top: 8px;
  color: #00d25b;
}
.energy_down {
  background: #322028;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  position: relative;
}
.energy_down svg {
  position: absolute;
  left: 8px;
  top: 8px;
  color: #c83b4a;
}
.span_text_container {
  display: flex;
  gap: 5px;
}
.span_text {
  color: #08d25b;
  font-size: 0.875rem;
}
.span_text_error {
  color: #dc2928;
  font-size: 0.875rem;
}
.header_icon{
 display: flex;
}
@media only screen and (max-width: 730px) {
  .gauge_container {
    position: relative;
    min-height: 245px;
    height: fit-content;
  }
  .gauge_consumotionpage {
    height: 200px;
  }
}
