.onoff {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  gap: 30px;
  /* background-color: #191c24; */
  padding: 10px;
  border-radius: 10px;
}
.AnalogGrid {
  margin: 10px;
}
.timer_container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.countdown_container {
  background-color: #333333;
  padding: 5px 3px;
  width: 60px;
}
.floating_btn{
  width: 80%;
  display: flex;
  justify-content: flex-end;
}.floating_button {
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.css-169iwlq-MuiCalendarPicker-root,
.MuiClockPicker-root {
  background-color: #282a37;
  border-radius: 20px;
}
.css-1faump4-MuiButtonBase-root-MuiPickersDay-root {
  background-color: transparent !important;
}
.css-7a0zf7-MuiPaper-root-MuiPickersPopper-paper {
  border-radius: 20px !important;
}
.css-169iwlq-MuiCalendarPicker-root {
  border-radius: 20px !important;
}
.timer_container .css-2hdwtx-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
}
