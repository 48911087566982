.main_menu_container {
  display: flex;
  position: relative;
}
.main_menu_top_icons {
  position: absolute;
  left: 8px;
  top: 8px;
  color: #00d25b;
}
.main_menu_top_icons1 {
  position: absolute;
  left: 8px;
  top: 8px;
  color: #b52328;
}
.text {
  margin: 5px auto;
  font-family: rubik;
  color: #6c7293;
}
.main_menu_container h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main_menu_progress_container {
  position: relative;
  height: 30px;
}
.line_chart_mainmenu {
  margin-top: 0;
  position: relative;
  border-radius: 5px;
}
.line_chart_bottom {
  text-align: center;
  color: grey;
  width: 100%;
  bottom: 5px;
}
.line_chart_left {
  color: grey;
  width: 100%;
  position: absolute;
  left: 0;
  rotate: 90deg;
  bottom: 0;
  writing-mode: vertical-rl;
}
.box {
  background: #183022;
  height: 40px;
  width: 40px;
  border-radius: 20px;
}
.box1 {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #322028;
}
