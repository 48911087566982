.piechart_mainmenu {
  position: relative;
}
.piechart_mainmenu h5 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
  margin: auto;
  font-weight: 600;
}
.power_mainmenu {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
}
.power_mainmenu_container {
  display: flex;
  justify-content: space-between;
  width: 48%;
  padding: 25px 20px;
  align-items: center;
  margin: 0%;
  border-radius: 5px;
}
.main_menu_righticon {
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.power_mainmenu_container_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  row-gap: 15px;
  margin: 5px 10px;
  margin-top: 20px;
}
.css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #f1f1f1;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  border: none !important;
}
.progress_left_mainmenu {
  position: absolute;
  left: 3px;
  top: 2.5px;
}
.datagrid_header {
  color: white;
}
.css-14k015g-MuiDataGrid-root {
  border: none;
}
.MuiDataGrid-columnSeparator {
  display: none;
}
.nameSubmitbutton {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 730px) {
  .power_mainmenu_container {
    width: 100%;
    border-radius: 5px;
  }
}
